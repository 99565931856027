.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Breath {
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 15px;
  max-width: calc(80vw - 30px);
  max-height: calc(80vw - 30px);
}

.Breath__Label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 21px;
  opacity: 0;
  transition: all 0.9s ease;
}

.Breath__Input:focus ~ .Breath__Label {
  opacity: 0.1;
}

.Breath__Label:nth-child(2) {
  justify-content: flex-end;
  transform: translateX(-100%);
  transition-delay: 0.2s;
}
.Breath__Label:nth-child(3) {
  align-items: flex-end;
  transform: translateY(-100%);
  transition-delay: 0.25s;
}
.Breath__Label:nth-child(4) {
  justify-content: flex-start;
  transform: translateX(100%);
  transition-delay: 0.3s;
}
.Breath__Label:nth-child(5) {
  align-items: flex-start;
  transform: translateY(100%);
  transition-delay: 0.35s;
}

@media screen and (max-width: 800px) {
  .Breath__Label {
    opacity: 0 !important;
    transform: scale(0.5);
  }
}

.Breath__Cursor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -20px;
  left: -20px;
  z-index: 2;
}

.Breath__Cursor:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: currentColor;
  border-radius: 50%;
}

.Breath__Input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 0;
  outline: 0;
  font-size: 20px;
  background: none;
  appearance: none;
  opacity: 0;
  transition: opacity 1s ease;
  appearance: none;
  color: inherit;
  z-index: 99;
}
.Breath__Input:focus {
  opacity: 1;
}

.Breath__Input::-webkit-inner-spin-button,
.Breath__Input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Breath__Icon {
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 1s ease;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 500px;
  max-width: 80vw;
  z-index: 1;
}

.Breath__Icon ellipse {
  fill: #f25e5e;
  animation-duration: 40s;
  animation: none;
  cx: 250;
  cy: 250;
  rx: 80;
  ry: 90;
}

.Breath__Icon ellipse:nth-child(1) {
  cx: 180;
  cy: 280;
}
.Breath__Icon ellipse:nth-child(2) {
  cy: 270;
}
.Breath__Icon ellipse:nth-child(3) {
  cx: 200;
}
.Breath__Icon ellipse:nth-child(4) {
  cx: 280;
  cy: 200;
}

.Breath__Input:focus ~ .Breath__Icon {
  opacity: 0.1;
}
